<template>
  <div class="ms_main_wrapper">
    <app-side-menu />
    <app-header />
    <Suspense>
      <template #default>
        <app-login />
      </template>
      <template #fallback>
        <app-loader />
      </template>
    </Suspense>
    <app-bottom-navbar />
  </div>
</template>

<script>
import AppHeader from "../../components/Include/AppHeader.vue";
import AppSideMenu from "../../components/Include/AppSideMenu.vue";
import { defineAsyncComponent, Suspense } from "vue";
import AppLoader from "../../components/Spinners/AppLoader.vue";
import AppBottomNavbar from "../../components/Include/AppBottomNavbar.vue";
const AppLogin = defineAsyncComponent(() =>
  import("../../components/Users/AppLogin.vue")
);
export default {
  name: "Login",
  components: {
    AppSideMenu,
    AppHeader,
    Suspense,
    AppLoader,
    AppBottomNavbar,
    AppLogin,
  },
};
</script>

<style>
</style>